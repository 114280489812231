<template>
    <div>
        <b-button
                v-b-modal="'move-department'"
                class="btn btn-warning btn-sm"
                :class="{ 'spinner spinner-white spinner-left': loadingMoveDepartment}"
                :title="ticket.is_feedback ? 'Đã nhập đánh giá không được phép chuyển phòng ban' : ''"
                :disabled="ticket.is_feedback || loadingMoveDepartment"
        >Chuyển phòng ban
        </b-button>
        <b-modal id="move-department"
                 title="Chuyển phòng ban"
                 size="xs"
                 ok-title="Cập nhật"
                 ok-only
                 centered
                 hide-footer
        >
            <ValidationObserver v-slot="{ handleSubmit }" ref="form">
                <form>
                    <div class="form-group">
                        <ValidationProvider vid="feedback_ticket_comment" name="Nội dung" rules="required"
                                            v-slot="{ errors,classes }">
                            <div class="form-group">
                                <label>Phòng ban xử lý<span class="text-danger">*</span></label>
                                <ValidationProvider vid="phone" name="Phòng ban xử lý"
                                                    :rules="{ required: true }"
                                                    v-slot="{ errors,classes }">
                                    <el-select filterable class="w-100" placeholder=""
                                               v-model="phong_ban_xu_ly"
                                               clearable

                                    >
                                        <el-option
                                                v-for="item in departments"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                        >
                                            <span style="float: left">{{ item.name }}</span>
                                        </el-option>
                                    </el-select>
                                    <div class="fv-plugins-message-container">
                                        <div data-field="name" data-validator="notEmpty"
                                             class="fv-help-block">{{
                                            errors[0] }}
                                        </div>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                                    errors[0]
                                    }}
                                </div>
                            </div>
                        </ValidationProvider>
                    </div>
                </form>
            </ValidationObserver>
            <b-button
                    variant="success"
                    size="md"
                    class="float-right"
                    :disabled="loadingMoveDepartment"
                    :class="{'spinner spinner-white spinner-right' : loadingMoveDepartment}"
                    @click="handleOk"
            >
                Chuyển
            </b-button>
        </b-modal>
    </div>
</template>

<script>
    import {UPDATE_TICKET_FORM} from "../../../../../core/services/store/user/ticket.module";

    export default {
        name: "MoveDepartmentModal",
        props: {
            departments: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            ticket: {
                type: Object,
                default: () => {
                    return null;
                }
            },
        },
        data() {
            return {
                loadingMoveDepartment: false,
                phong_ban_xu_ly: ''
            }
        },
        mounted() {
            if (this.ticket.phong_ban_xu_ly == 1) {
                this.phong_ban_xu_ly = '';
            } else {
                this.phong_ban_xu_ly = this.ticket.phong_ban_xu_ly;
            }
        },
        methods: {
            handleOk(e) {
                e.preventDefault();
                this.closeModal();
                this.ticket.phong_ban_xu_ly = this.phong_ban_xu_ly;
                this.loadingMoveDepartment = true;
                let ticket_change = this.ticket;
                ticket_change.recipient_id = 3;
                ticket_change.is_transfer =  true;
                this.$store.dispatch(UPDATE_TICKET_FORM, ticket_change).then((data) => {
                    this.$bvToast.toast('Chuyển phòng ban xử lý thành công', {
                        title: 'Thành công',
                        variant: 'success',
                        solid: true
                    });
                    this.loadingMoveDepartment = false;
                    this.$emit('moveDepartment');
                });
            },
            closeModal() {
                this.$bvModal.hide('move-department');
            }
        }
    }
</script>

<style scoped>

</style>